<template>
	<div class="container-fluid pt-5 mt-5">
		
		<div class="row text-center">
			<div class="col-md-4">
				<button class="btn btn-primary" @click="subscribeCheck('solo')">{{ $t('SubscribeToSolo')}}</button><br>
			</div>
			<div class="col-md-4">
				<button class="btn btn-primary" @click="subscribeCheck('team')">{{ $t('SubscribeToTeam')}}</button><br>
			</div>
			<div class="col-md-4">
				<button class="btn btn-primary" @click="subscribeCheck('agency')">{{ $t('SubscribeToAgency')}}</button><br>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		
		data() {
			return {
				
			}
		},
		methods: {
			subscribeCheck(element) {
				console.log(element)
				if( element == process.env.VUE_APP_PLAN_SOLO ) {
					window.Paddle.Checkout.open({ product: 588493 })
				} else if( element == 'team' ) {
					window.Paddle.Checkout.open({ product: 588340 })
				} else if( element == process.env.VUE_APP_PLAN_FULL ) {
					window.Paddle.Checkout.open({ product: 583039 })
				}
			}
		}
	}
</script>